






import VueApexCharts from "vue-apexcharts";
import { Vue, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { CategoryChartSeries } from "@/models/reporting-analytics/category-chart-data";
import { reportingAnalyticsService } from "@/services/reporting-analytics.service";
import AutomationAnalyticsStore from "@/store/reporting-analytics/automation.store";
import Catch from "@/shared/decorators/catch-errors";
import { reportErrorCaptured } from "@/helpers/error-captured";
import moment from "moment";
import { utils } from "@/utils/okta-utils";

@Component({
  components: { apexchart: VueApexCharts }
})
export default class AutoAddInvoicesChart extends Vue {
  // store module
  private readonly automationAnalyticsStore: AutomationAnalyticsStore = getModule(
    AutomationAnalyticsStore,
    this.$store
  );

  // computed properties
  private get autoAddInvoiceData() {
    return this.automationAnalyticsStore.getAutoAddInvoiceData;
  }
  private get autoAddSampleSizeData() {
    return this.automationAnalyticsStore.getAutoAddSampleSizeData;
  }
  private get dateRange() {
    return this.automationAnalyticsStore.getDateRange;
  }
  private get searchButtonClicked() {
    return this.automationAnalyticsStore.getSearchButtonClicked;
  }

  // reactive class properties
  private series: CategoryChartSeries[] = [];
  private headerRecogSeries: CategoryChartSeries[] = [];
  private chartOptions = {
    chart: {
      fontFamily: "'Lato', sans-serif",
      toolbar: {
        export: {
          csv: {
            filename: `auto-add-${this.dateRange.startDate}-to-${this.dateRange.endDate}`,
            columnDelimiter: ",",
            headerCategory: "Entered Date"
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [2, 0],
      curve: "smooth",
      lineCap: "round"
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    yaxis: [
      {
        title: {
          text: "Percent automatically entered"
        },
        labels: {
          formatter: function(value: number) {
            if (value) {
              return Math.floor(value);
            }
          }
        },
        min: 0,
        max: 100
      },
      {
        opposite: true,
        title: {
          text: "Documents submitted"
        },
        labels: {
          formatter: function(value: number) {
            if (value) {
              return Math.floor(value);
            }
          }
        }
      }
    ],
    xaxis: {
      type: "category",
      title: {
        text: "Entered date"
      },
      labels: {
        formatter: function(value: any) {
          if (value) {
            return utils.utcToLocalDateString(moment(value).toDate(), "L");
          }
        }
      }
    },
    title: {
      text: "Documents Submitted to Workday",
      align: "left"
    },
    subtitle: {
      text: "",
      align: "left"
    },
    tooltip: {
      shared: true,
      followCursor: true,
      y: [
        {
          formatter: function(value: number) {
            if (value) {
              return value.toFixed(2) + "%";
            }
          }
        },
        {
          formatter: function(value: number) {
            if (value) {
              return parseFloat(value.toFixed()).toLocaleString();
            }
          }
        }
      ]
    },
    noData: {
      text: "No data for selected options",
      align: "center"
    }
  };

  // methods
  @Catch(reportErrorCaptured)
  async fetchNewChartData() {
    this.$emit("loading");
    const chartData: any = await reportingAnalyticsService.loadInvoicesAutoAddedAsync(
      this.dateRange.startDate,
      this.dateRange.endDate
    );
    this.automationAnalyticsStore.updateAutoAddInvoiceData(
      chartData.invoicesAutoAdded
    );
    this.automationAnalyticsStore.updateAutoAddSampleSizeData(
      chartData.invoicesAdded
    );
  }

  updateChart() {
    this.series = [
      {
        name: "Percent automatically entered",
        data: this.autoAddInvoiceData,
        color: "#07445b",
        type: "line"
      },
      {
        name: "Total documents submitted",
        data: this.autoAddSampleSizeData,
        color: "#D06C49",
        type: "bar"
      }
    ];
  }

  // component lifecycle methods
  async created() {
    if (this.autoAddInvoiceData.length === 0) {
      await this.fetchNewChartData();
    }
    this.updateChart();
    this.$emit("chartLoaded");
  }

  // watchers
  @Watch("searchButtonClicked")
  async updateSearch() {
    await this.fetchNewChartData();
    this.updateChart();
    this.$emit("chartLoaded");
  }
}
