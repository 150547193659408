





























































// Custom Components
import AutoAddInvoicesChart from "@/components/reporting-analytics/charts/AutoAddInvoices.vue";
import DateRangeSelector from "@/components/forms/reporting-analytics/DateRangeSelector.vue";
import DataSnapshot from "@/components/reporting-analytics/data/DataSnapshot.vue";
import InvoiceVolumeAnalyticsSkeleton from "@/components/skeletons/InvoiceVolumeAnalyticsSkeleton.vue";
import InvoiceVolumeByProcessorChart from "@/components/reporting-analytics/charts/InvoicesByProcessor.vue";

// Shared
import Catch from "@/shared/decorators/catch-errors";
import { reportErrorCaptured } from "@/helpers/error-captured";
import { reportingAnalyticsService } from "@/services/reporting-analytics.service";

// Store
import AutomationAnalyticsStore from "@/store/reporting-analytics/automation.store";

// Third Party Libs
import { getModule } from "vuex-module-decorators";
import { Vue, Component, Watch } from "vue-property-decorator";

@Component({
  components: {
    "date-range-selector": DateRangeSelector,
    "data-snapshot": DataSnapshot,
    "auto-add-chart": AutoAddInvoicesChart,
    "invoice-volume-processor-chart": InvoiceVolumeByProcessorChart,
    skeleton: InvoiceVolumeAnalyticsSkeleton
  }
})
export default class TouchlessProcessing extends Vue {
  // store modules
  private automationAnalyticsStore: AutomationAnalyticsStore = getModule(
    AutomationAnalyticsStore,
    this.$store
  );

  // reactive class properties
  private autoAddChartLoading = false;
  private processorChartLoading = false;

  // computed properties
  private get dateRange() {
    return this.automationAnalyticsStore.getDateRange;
  }
  private get numOfApProcessors() {
    return this.automationAnalyticsStore.getNumOfApProcessors;
  }
  private get numOfApProcessorsCaption() {
    return this.numOfApProcessors === 1 ? "AP Specialist" : "AP Specialists";
  }
  private get autoAddSetting() {
    return this.automationAnalyticsStore.getAutoAddSetting;
  }
  private get autoAddDataSnapshot() {
    return this.automationAnalyticsStore.getAutoAddDataSnapshot;
  }
  private get searchButtonClicked() {
    return this.automationAnalyticsStore.getSearchButtonClicked;
  }
  private get touchlessProcessingDataSnapshot() {
    return this.automationAnalyticsStore.getTouchlessProcessingDataSnapshot;
  }

  // methods
  handleDateRangeChange(selection: { startDate: string; endDate: string }) {
    this.automationAnalyticsStore.updateDateRange(selection);
    this.automationAnalyticsStore.clickSearchButton();
  }

  autoAddChartLoaded() {
    this.autoAddChartLoading = false;
  }
  processorChartLoaded() {
    this.processorChartLoading = false;
  }

  @Catch(reportErrorCaptured)
  async fetchNewTouchlessProcessingData() {
    await reportingAnalyticsService.loadTouchlessProcessingSnapshotAsync(
      this.dateRange.startDate,
      this.dateRange.endDate
    );
  }

  // lifecycle methods
  async created() {
    await this.fetchNewTouchlessProcessingData();
  }

  // watchers
  @Watch("searchButtonClicked")
  async updateSearch() {
    await this.fetchNewTouchlessProcessingData();
  }
}
