






import VueApexCharts from "vue-apexcharts";
import { Vue, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
  CategoryChartData,
  CategoryChartSeries
} from "@/models/reporting-analytics/category-chart-data";
import { reportingAnalyticsService } from "@/services/reporting-analytics.service";
import AutomationAnalyticsStore from "@/store/reporting-analytics/automation.store";
import Catch from "@/shared/decorators/catch-errors";
import { reportErrorCaptured } from "@/helpers/error-captured";
import { InvoiceVolumeCharts } from "@/components/reporting-analytics/common/invoices-single-column-chart-options";

@Component({
  components: { apexchart: VueApexCharts }
})
export default class InvoiceVolumeByProcessorChart extends Vue {
  // store module
  private readonly automationAnalyticsStore: AutomationAnalyticsStore = getModule(
    AutomationAnalyticsStore,
    this.$store
  );

  // computed properties
  get invoiceVolumeByProcessorData() {
    return this.automationAnalyticsStore.getInvoiceVolumeByProcessorData;
  }
  get dateRange() {
    return this.automationAnalyticsStore.getDateRange;
  }
  get searchButtonClicked() {
    return this.automationAnalyticsStore.getSearchButtonClicked;
  }

  // reactive class properties
  private series: CategoryChartSeries[] = [];
  private chartOptions = InvoiceVolumeCharts.singleColumnBaseChartOptions(
    "document-volume-by-specialist",
    this.dateRange,
    "AP Specialist",
    "Documents submitted",
    "AP Specialist",
    "Documents Submitted by AP Specialist"
  );

  // lifecycle methods
  async created() {
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        ...this.chartOptions.xaxis,
        labels: {
          ...this.chartOptions.xaxis.labels,
          trim: true
        }
      }
    };
    if (this.invoiceVolumeByProcessorData.length === 0) {
      await this.fetchNewChartData();
    }
    this.updateChart();
    this.$emit("chartLoaded");
  }

  // methods
  @Catch(reportErrorCaptured)
  async fetchNewChartData() {
    this.$emit("loading");

    const chartData: CategoryChartData[] = await reportingAnalyticsService.loadInvoicesByProcessorAsync(
      this.dateRange.startDate,
      this.dateRange.endDate
    );
    this.automationAnalyticsStore.updateInvoiceVolumeByProcessorData(chartData);
  }

  updateChart() {
    this.series = [
      {
        name: "Documents submitted",
        data: this.invoiceVolumeByProcessorData
      }
    ];
  }

  // watchers
  @Watch("searchButtonClicked")
  async updateSearch() {
    await this.fetchNewChartData();
    this.updateChart();
    this.$emit("chartLoaded");
  }
}
